import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';
import { Category } from '@app/api/models/category';

export const links = {
    origin: 'https://servicesales.ru',
    email: "info@servicesales.ru",
    email_support: "support@servicesales.ru",
    footer: {
        column_one: [
            {
                url: '/',
                id: 'main',
                text: 'footer.main'
            },
            {
                url: NavPath.About,
                id: 'about',
                text: 'footer.about'
            },
            // ru & com differ
            {
                url: NavPath.ProfessionalLanding,
                id: 'professional-landing',
                text: 'footer.list-your-business'
            },
            {
                url: NavPath.ProfessionalSchedule,
                id: 'professional-schedule',
                text: 'footer.professional-schedule'
            },
            // only ru
            {
                url: `${NavPath.Service}/${NavBranch.Publish}`,
                id: 'service-publish',
                text: 'creative-banners.service-publish'
            },
            {
                url: NavPath.CreativeBanners,
                id: 'client-applications',
                text: 'footer.client-applications'
            },
            {
                url: NavPath.Blog,
                id: 'blog',
                text: 'blog.title'
            }
        ],
        column_two: [
            // only ru
            {
                url: NavPath.Locations,
                id: 'locations',
                text: 'footer.locations'
            },
            {
                url: NavPath.Support,
                id: 'support-videos',
                text: 'footer.support-page'
            },
            {
                url: NavPath.Eula,
                id: 'eula',
                text: 'footer.eula'
            },
            {
                url: NavPath.Policy,
                id: 'policy',
                text: 'footer.policy'
            }
        ]
    },
    domain: "https://servicesales.ru",
    catalogLinks: (category: Category) => `/${NavPath.ServiceCatalog}`,
    catalogParams: (id: number) => { return { id: id } }
};
